import React, { useState } from "react";
import img1 from "../../assets/images/qr.jpeg";
import "./DonationForm.css";

const DonationForm = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [donation, setDonation] = useState({
    name: "",
    email: "",
    mobile: "",
    rupee: "",
    account: "",
    cheque: "",
    rupees: "",
  });

  const handelDonation = ({ target: { name, value } }) => {
    setDonation({
      ...donation,
      hasChanged: true,
      [name]: value,
    });
    console.log(donation);
  };

  return (
    <div className="donation">
      <div className="donation-content">
        <div className="donation-form">
          <div className="donation-info">
            <div className="name  paddingB" id="name">
              <label>Name:&nbsp;</label>
              <input
                type="text"
                name="name"
                value={donation.name}
                onChange={handelDonation}
                placeholder="Enter Your Name"
              />
            </div>

            <div className="mobile paddingB">
              <label>Mobile No.:&nbsp;</label>
              <input
                type="tel"
                value={donation.mobile}
                name="mobile"
                onChange={handelDonation}
                placeholder="Mobile No."
              />
            </div>

            <div className="email paddingB" id="inputemail">
              <label>Email ID:&nbsp;</label>
              <input
                type="email"
                name="email"
                value={donation.email}
                onChange={handelDonation}
                placeholder="Enter Your Email"
              />
            </div>

            <div className="amount paddingB">
              <label>Donation Amount:&nbsp;</label>
              <input
                type="text"
                name="rupee"
                value={donation.rupee}
                onChange={handelDonation}
                placeholder="&#x20b9;"
              />
            </div>

            <div className="cheque paddingB">
              <label>Cheque/RTGS/UPI/DD No.:&nbsp;</label>
              <input
                type="text"
                name="cheque"
                value={donation.cheque}
                onChange={handelDonation}
                placeholder="Cheque/RTGS/UPI/DD No."
              />
            </div>

            <div className="upload paddingB">
              <label>Upload Reciept:&nbsp;</label>
              {selectedImage && (
                <div>
                  {/* <img
                          alt="not found"
                          width={"250px"}
                          src={URL.createObjectURL(selectedImage)}
                        /> */}
                  <button onClick={() => setSelectedImage(null)}>Remove</button>
                </div>
              )}

              <input
                type="file"
                name="myImage"
                required
                value={donation.file}
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </div>

            <div className="transactionId paddingB">
              <label>Transaction ID:&nbsp;</label>
              <input type="text" placeholder="Transaction ID" />
            </div>
          </div>

          <div className="donation-qr paddingB">
            <div className="QR paddingB">
              <img src={img1} alt="" />
            </div>

            <div className="account-details">
              <p>UPI ID: 7627888222@sbi</p>
            </div>
          </div>
        </div>
        <div className="donation-btn">
          <button>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
