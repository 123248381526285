import React from 'react';

import './App.css';
import Footer from './components/footer/Footer';
import Gallery from './components/gallery/Gallery';
import Mission from './components/mission/Mission';
import Home from './components/Home/Home';
import Research from './components/research/Research'
import Newsletter from './components/newsletter/Newsletter';
import Development from './components/development/Development';
import Stories from './components/stories/Stories';
import Announcement from './components/announcement/Announcement';
import img1 from "./assets/images/research.jpeg"


import newsData from "./records.json";

function App() {

const Data = [
  {
    id: 1,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "Social Skills Program",
    desc: "Pedagogy refers to the art and science of teaching. It encompasses instructional methods, educational theory, and the practical application of teaching strategies. In the context of the Institute of Training and Research, our research on pedagogic practices delves into enhancing teaching methodologies for more effective learning outcomes.",
  },
  {
    id: 2,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "Pedagogic",
    desc: "Social skills are integral to personal and professional success. The Social Skills Program research at the Institute of Training and Research aims to identify, develop, and implement strategies that foster positive social interactions.",
  },
  {
    id: 3,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "School Education",
    desc: " School education forms the foundation of a student's academic journey. Our research in school education focuses on refining curriculum, teaching methods, and creating supportive environments.",
  },
  {
    id: 4,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "Community Education",
    desc: "Community education extends learning beyond the traditional classroom setting. It involves engaging and empowering communities through knowledge-sharing and skill-building initiatives.",
  },
  {
    id: 5,
    imgSrc: img1,
    imgTitle: "Research",
    heading: " Educational Technology Impact",
    desc: " Exploring the impact of emerging technologies such as AI, virtual reality, and online learning on education.",
  },
  {
    id: 5,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "Cultural Competency in Education",
    desc: "Studying the importance of cultural competency in educators and its influence on creating inclusive learning environments.",
  },
  {
    id: 5,
    imgSrc: img1,
    imgTitle: "Research",
    heading: "Environmental Education",
    desc: "Investigating ways to incorporate environmental education intothe curriculum to foster environmental stewardship.",
  },
];

  return (
   <>
    <Home />
    <Mission />
    <Gallery />
    {/* <Modal /> */}
    <Announcement />
    <Development />
    <Research Data={Data} name ="RESEARCH" />
    <Newsletter/>
    <Stories />
    <Footer />
   </>
  );
}

export default App;
