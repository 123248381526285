import React, { useEffect, useState } from "react";import "./Development.css";

import { Carousel } from "antd";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import { FaChalkboardTeacher } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaBookReader } from "react-icons/fa";
import { MdManageHistory } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleRight
      className={className}
      style={{ ...style, display: "block", color: "rgb(255,91,39)" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleLeft
      className={className}
      style={{ ...style, display: "block", color: "rgb(255,91,39)" }}
      onClick={onClick}
    />
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};


const Development = () => {

  const [visibleThumbnails, setVisibleThumbnails] = useState(4);

  useEffect(() => {
    const calculateVisibleThumbnails = () => {
      const windowWidth = window.innerWidth;
      let newVisibleThumbnails = 4; // Default number of visible thumbnails
      if (windowWidth < 768) {
        newVisibleThumbnails = 1;
      } else if (windowWidth < 992) {
        newVisibleThumbnails = 2;
      }
      setVisibleThumbnails(newVisibleThumbnails);
    };

    calculateVisibleThumbnails();

    window.addEventListener("resize", calculateVisibleThumbnails);

    return () => {
      window.removeEventListener("resize", calculateVisibleThumbnails);
    };
  }, []);

  return (
    <div className="development">
      <div className="dev-container">
        <h2 className="heading">DEVELOPMENT PROGRAM</h2>

        <Carousel
          slidesToShow={visibleThumbnails}  arrows {...settings}
        >
          <div className="dev-carousel" >
          <div className="dev-content">
            <div className="dev-icon">
              <FaChalkboardTeacher />
            </div>
            <div className="dev-text">
              <a href="#"><h6>TEACHER DEVELOPMENT PROGRAM</h6></a>
              <a href="#">
              <p>
                This program focuses on equipping teachers with advanced
                pedagogical techniques to engage students effectively and foster
                a conducive learning environment
              </p>
              </a>
            </div>
          </div>
          </div>

          <div className="dev-carousel" >
          <div className="dev-content">
            <div className="dev-icon">
              <FaLaptopCode />
            </div>
            <div className="dev-text">
              <a href="#"><h6>SKILL DEVELOPMENT PROGRAM</h6></a>
              <a href="#">
              <p>
                This program provides teachers with hands-on training to
                integrate technology into their teaching practices, enabling
                them to leverage digital tools for enhanced learning outcomes.
              </p>
              </a>
            </div>
          </div>
          </div>

          <div className="dev-carousel" >
          <div className="dev-content">
            <div className="dev-icon">
              <FaBookReader />
            </div>
            <div className="dev-text">
              <a href="#"><h6>FACULTY DEVELOPMENT PROGRAM</h6></a>
              <a href="#">
              <p>
                Faculty members participate in workshops and seminars aimed at
                enhancing their research skills, including literature review,
                data analysis, and publication strategies.
              </p>
              </a>
            </div>
          </div>
          </div>

          <div className="dev-carousel" >
          <div className="dev-content">
            <div className="dev-icon">
              <MdManageHistory />
            </div>
            <div className="dev-text">
              <a href="#"><h6>MANAGEMENT DEVELOPMENT PROGRAM</h6></a>
              <a href="#">
              <p>
                This program focuses on developing leadership capabilities among
                academic leaders, equipping them with the skills needed to make
                informed decisions and lead teams effectively.
              </p>
              </a>
            </div>
          </div>
          </div>

          <div className="dev-carousel" >
          <div className="dev-content">
            <div className="dev-icon">
              <HiUserGroup />
            </div>
            <div className="dev-text">
              <a href="#"><h6>STAFF DEVELOPMENT PROGRAM</h6></a>
              <a href="#">
              <p>
                This program provides administrative staff with training in
                essential skills such as time management, organization, and
                office software proficiency.
              </p>
              </a>
            </div>
          </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Development;
