import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import About from './pages/About/About';
import Donation from './pages/Donation/Donation';
import SignUp from './components/Signup/SignUp';
import HomeAdmin from './pages/HomeAdmin';
import Login from './components/Login/Login';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/donation",
    element: <Donation />
  },
  {
    path: "/Signup",
    element: <SignUp/>
  },
  {
    path: "/Login",
    element: <Login/>
  },
  {
    path: "/admin/home",
    element: <HomeAdmin/>
  }

]);

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: isAuthenticated(state),

//   };
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// export default withRouter(connect(mapStateToProps)(App))
