import React, { useEffect, useState } from "react";
import "./Research.css";

import { Carousel } from "antd";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import "react-multi-carousel/lib/styles.css";

import img1 from "../../assets/images/research.jpeg";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleRight
      className={className}
      style={{ ...style, display: "block", color: "rgb(255,91,39)" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaAngleLeft
      className={className}
      style={{ ...style, display: "block", color: "rgb(255,91,39)" }}
      onClick={onClick}
    />
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Research = (props) => {
  const { Data } = props;

  const [visibleThumbnails, setVisibleThumbnails] = useState(4);

  useEffect(() => {
    const calculateVisibleThumbnails = () => {
      const windowWidth = window.innerWidth;
      let newVisibleThumbnails = 4; // Default number of visible thumbnails
      if (windowWidth < 768) {
        newVisibleThumbnails = 1;
      } else if (windowWidth < 992) {
        newVisibleThumbnails = 2;
      }
      setVisibleThumbnails(newVisibleThumbnails);
    };

    calculateVisibleThumbnails();

    window.addEventListener("resize", calculateVisibleThumbnails);

    return () => {
      window.removeEventListener("resize", calculateVisibleThumbnails);
    };
  }, []);

  return (
    <div className="research">
      <div className="research-container">
        <div className="research-header">
          <h2 className="heading">{props.name}</h2>
        </div>

        <Carousel slidesToShow={visibleThumbnails}  className="Carousel" arrows {...settings}>
          {Data.map(({ id, imgSrc, imgTitle, heading, desc }) => {
            return (
              <div className="somethin">
              <div className="research-content" key={id}>
                <div className="research-img">
                  <img src={imgSrc} alt={imgTitle} />
                </div>
                <div className="research-text">
                  <h6 className="research-heading">{heading}</h6>

                  <p>{desc}</p>
                  <a href="#">Read More...</a>
                </div>
              </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Research;
