import React from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";
import img1 from "../../assets/images/bg.png";

const Home = () => {
  return (
    <section className="home">
      <Navbar />
      <div className="Container">
        {
          <>
            <div className="homeText">
              <h4 className="title">Welcome to</h4>
              <h1 className="title">VB INSTITUTE OF TRAINING & RESEARCH</h1>
              <p className="subTitle">
              A dynamic and progressive institution committed to empowering educators, fostering pedagogical innovation, and preparing teachers for excellence in their educational endeavors. VB Institute of Training and Research is conceptualised by Dr Thakur SKR - A Visionary ISRO Scientist and Prominent Social Worker under the guidance of Mr. Vijay Nadda - A Forward Thinking RSS Pracharak and Educationist to develop School Teachers to meet the Challenging Needs of the time. It  serves as a guiding light for educators, offering a comprehensive platform that blends advanced teaching methodologies with robust research practices. We are dedicated to shaping teachers who not only impart knowledge effectively but also inspire and guide students towards academic success and holistic development.
              </p>
            </div>

            <div className="homeImage">
              <img src={img1} alt="" />
            </div>
          </>
        }
      </div>
    </section>
  );
};


export default Home;
