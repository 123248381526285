import React, { useEffect, useState } from "react";
import "./Stories.css";

import { Carousel } from "antd";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import img1 from "../../assets/images/thakur.jpeg";
import img2 from "../../assets/images/gallery3.jpeg";



const SampleNextArrow = props => {
  const { className, style, onClick } = props
  return (
   <FaAngleRight className={className}
  style={{ ...style, display: 'block', color: 'rgb(255,91,39)' }}
  onClick={onClick} />
  )
}

const SamplePrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <FaAngleLeft
      className={className}
      style={{ ...style, display: 'block' , color: 'rgb(255,91,39)'}}
      onClick={onClick}
    />
  )
}

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}


const Stories = () => {
  const [visibleThumbnails, setVisibleThumbnails] = useState(3);

  useEffect(() => {
    const calculateVisibleThumbnails = () => {
      const windowWidth = window.innerWidth;
      let newVisibleThumbnails = 3; // Default number of visible thumbnails
      if (windowWidth < 768) {
        newVisibleThumbnails = 1;
      } else if (windowWidth < 992) {
        newVisibleThumbnails = 2;
      }
      setVisibleThumbnails(newVisibleThumbnails);
    };

    calculateVisibleThumbnails();

    window.addEventListener("resize", calculateVisibleThumbnails);

    return () => {
      window.removeEventListener("resize", calculateVisibleThumbnails);
    };
  }, []);
  return (
    <div className="stories">
      <div className="stories-container">
        <h2 className="heading">OUR TEAM</h2>
     
          
      
        <Carousel slidesToShow={visibleThumbnails}  className="Carousel" arrows {...settings}>
          <div className="stories-content">
            <div className="profile">
              <img src={img2} alt="" />
              <div className="profile-content">
                <h5>Shri Ashok pal</h5>
                <p>PATRON</p>
              </div>
            </div>
            <div className="stories-para">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printi typese tting
                industry. Lorem Ipsum has been standar d dummy text ever since
                the 1500s, when a print er took a galley of type and scrambled
                it to make a type specimen book */}
              </p>
            </div>
          </div>

          <div className="stories-content">
            <div className="profile">
              <img src={img1} alt="" />
              <div className="profile-content">
                <a href="https://drthakurskr.com/" target="_blank">
                  <h5>Dr. Thakur SKR</h5>
                </a>
                <p>DIRECTOR</p>
              </div>
            </div>
            <div className="stories-para">
              {/* <p>
              Thakur Sudesh Kumar Raunija, popularly known as Dr Thakur SKR, is an IITD alumnus, a prominent scientist of ISRO, an ardent author and a life coach. In scientific world, he is cited and abbreviated as TSK RAUNIJA. At present, Dr Thakur SKR is working as Sci/Engr-SF @Indian Space Research Organisation. He also currently serves as the Founder-Director of the Department of Holistic Education and Institute of Training and Research Chandigarh.
              </p> */}
            </div>
          </div>

          <div className="stories-content">
            <div className="profile">
              <img src={img2} alt="" />
              <div className="profile-content">
                <h5>Smt. Pratibha Gupta</h5>
                <p>PRESIDENT</p>
              </div>
            </div>
            <div className="stories-para">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printi typese tting
                industry. Lorem Ipsum has been standar d dummy text ever since
                the 1500s, when a print er took a galley of type and scrambled
                it to make a type specimen book */}
              </p>
            </div>
          </div>

          <div className="stories-content">
            <div className="profile">
              <img src={img2} alt="" />
              <div className="profile-content">
                <h5>Shri Kulvir Sharma</h5>
                <p>VICE-PRESIDENT</p>
              </div>
            </div>
            <div className="stories-para">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printi typese tting
                industry. Lorem Ipsum has been standar d dummy text ever since
                the 1500s, when a print er took a galley of type and scrambled
                it to make a type specimen book */}
              </p>
            </div>
          </div>

          <div className="stories-content">
            <div className="profile">
              <img src={img2} alt="" />
              <div className="profile-content">
                <h5>Shri Chander Has Gupta</h5>
                <p>SECRETARY</p>
              </div>
            </div>
            <div className="stories-para">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printi typese tting
                industry. Lorem Ipsum has been standar d dummy text ever since
                the 1500s, when a print er took a galley of type and scrambled
                it to make a type specimen book */}
              </p>
            </div>
          </div>

          <div className="stories-content">
            <div className="profile">
              <img src={img2} alt="" />
              <div className="profile-content">
                <h5>Shri Anurag Baila</h5>
                <p>TREASURER</p>
              </div>
            </div>
            <div className="stories-para">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printi typese tting
                industry. Lorem Ipsum has been standar d dummy text ever since
                the 1500s, when a print er took a galley of type and scrambled
                it to make a type specimen book */}
              </p>
            </div>
          </div>
        </Carousel>
      
        
      </div>
    </div>
  );
};

export default Stories;
