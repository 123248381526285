import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";

import img from "../../assets/images/logo.png";

const Navbar = () => {
  //show Navbar
  const [active, setActive] = useState("navbar");
  const showNav = () => {
    setActive("navbar activeNavbar");
  };

  //remove Navbar
  const removeNav = () => {
    setActive("navbar");
  };
  return (
    <div className="App">
      <header className="header">
        <div className="top-section">
          <div className="logo">
            <img src={img} alt="Institution Logo" />
            <div onClick={showNav} className="toggle-navbar">
            <RiMenu3Line />
            </div>
          </div>

          <div className="searchBar">
            <input
              type="text"
              className="searchInput"
              placeholder="Search..."
            />
            <button type="button">Search</button>
          </div>
        </div>

        <nav className={active}>
          <Link className="navLink" to="/">
            HOME
          </Link>
          <Link className="navLink" to="/about">
            ABOUT
          </Link>
          <a className="navLink" href="#programs">
            PROGRAMS
          </a>
          <a className="navLink" href="#initiatives">
            KEY INITIATIVES
          </a>
          <a className="navLink" href="#research">
            RESEARCH
          </a>
          <Link className="navLink" to="/donation">
            DONATION
          </Link>

          <div className="user-actions">
            <button className="btnn login" type="button">
              <Link to="/Login">LogIn</Link>
            </button>
            <button className="btnn signup" type="button">
              <Link to="/Signup">SignUp</Link>
            </button>
          </div>

          <div onClick={removeNav} className="close-navbar">
            <AiFillCloseCircle />
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
