import React, { useState } from "react";
import "./AdminNav.css";

const AdminNav = () => {
  const handleLogout = () => {
    // Add your logout logic here
    console.log("Logging out...");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">VB-ITR</div>
      {/* <button className="toggle-btn" onClick={toggleSidebar}>
          {isOpen ? 'Close' : 'Open'}
        </button> */}
      <ul className="sidebar-menu">
        <li>
          <a href="#">Dashboard</a>
        </li>
        <li>
          <a href="#">Event</a>
        </li>
        <li>
          <a href="#">Announcement</a>
        </li>
        <li>
          <a href="#">News</a>
        </li>
        <li>
          <a href="#">Sign Up</a>
        </li>
      </ul>
      <div className="logout-btn-container">
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default AdminNav;
