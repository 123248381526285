import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Newsletter from "../../components/newsletter/Newsletter";
import Gallery from "../../components/gallery/Gallery";
import Mission from "../../components/mission/Mission";

import img1 from "../../assets/images/research.jpeg";
import "./About.css";
import Research from "../../components/research/Research";

const differentiators = [
  {
    id: 1,
    desc: "Multidisciplinary Approach Integrating knowledge from various subjects to foster a holistic understanding of education.",
  },
  {
    id: 2,
    desc: "Technology Integration Emphasizing the use of modern educational technologies to enhance teaching methodologies.",
  },
  {
    id: 3,
    desc: "Inclusive Education Addressing diverse learning needs and promoting inclusive teaching practices.",
  },
  {
    id: 4,
    desc: "Continuous Professional Development Offering ongoing opportunities for educators to stay updated with the latest teaching methodologies.",
  },
];

const curriculum = [
  {
    id: 1,
    desc: "Educational Psychology and Pedagogy",
  },
  {
    id: 2,
    desc: "Curriculum Design and Development",
  },
  {
    id: 3,
    desc: "Inclusive Education and Special Education Strategies ",
  },
  {
    id: 4,
    desc: "Technology Integration in Education",
  },
  {
    id: 5,
    desc: "Outdoor and experiential learning spaces to facilitate hands-on experiences.",
  },
];

const infrastructure = [
  {
    id: 1,
    desc: "State-of-the-art classrooms, laboratories, and administrative offices.",
  },
  {
    id: 2,
    desc: "Advanced technology infrastructure with interactive whiteboards and educational software.",
  },
  {
    id: 3,
    desc: "Extensive library resources and research facilities.",
  },
  {
    id: 4,
    desc: "Outdoor and experiential learning spaces to facilitate hands-on experiences.",
  },
];
const Data = [
    {
      id: 1,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "Social Skills Program",
      desc: "Pedagogy refers to the art and science of teaching. It encompasses instructional methods, educational theory, and the practical application of teaching strategies. In the context of the Institute of Training and Research, our research on pedagogic practices delves into enhancing teaching methodologies for more effective learning outcomes.",
    },
    {
      id: 2,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "Pedagogic",
      desc: "Social skills are integral to personal and professional success. The Social Skills Program research at the Institute of Training and Research aims to identify, develop, and implement strategies that foster positive social interactions.",
    },
    {
      id: 3,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "School Education",
      desc: " School education forms the foundation of a student's academic journey. Our research in school education focuses on refining curriculum, teaching methods, and creating supportive environments.",
    },
    {
      id: 4,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "Community Education",
      desc: "Community education extends learning beyond the traditional classroom setting. It involves engaging and empowering communities through knowledge-sharing and skill-building initiatives.",
    },
    {
      id: 5,
      imgSrc: img1,
      imgTitle: "Research",
      heading: " Educational Technology Impact",
      desc: " Exploring the impact of emerging technologies such as AI, virtual reality, and online learning on education.",
    },
    {
      id: 5,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "Cultural Competency in Education",
      desc: "Studying the importance of cultural competency in educators and its influence on creating inclusive learning environments.",
    },
    {
      id: 5,
      imgSrc: img1,
      imgTitle: "Research",
      heading: "Environmental Education",
      desc: "Investigating ways to incorporate environmental education intothe curriculum to foster environmental stewardship.",
    },
  ];

const About = () => {
  return (
    <div>
      <section className="home">
        <Navbar />
        <div className="about-title">
          <h2>
            VB-ITR <span>/ABOUT</span>
          </h2>
          <p>
            A dynamic and progressive institution committed to empowering
            educators, fostering pedagogical innovation, and preparing teachers
            for excellence in their educational endeavors. VB Institute of
            Training and Research serves as a guiding light for educators,
            offering a comprehensive platform that blends advanced teaching
            methodologies with robust research practices. We are dedicated to
            shaping teachers who not only impart knowledge effectively but also
            inspire and guide students towards academic success and holistic
            development.
          </p>
        </div>
      </section>

      <Research Data={Data} name ="WHO WE ARE?" />

      <Mission />
      <Newsletter />
      <Gallery />

      <div className="about-container">
        <div className="upper">
          <div className="content-heading">
            <h2>KEY DIFFERENTIATORS</h2>
          </div>

          {differentiators.map(({ id, desc }) => {
            return (
              <div className="about-content">
                <p>{desc}</p>
                <div className="underline-box"></div>
              </div>
            );
          })}
        </div>

        <div className="lower">
          <div className="lower1">
            <div className="content-heading">
              <h2>CURRICULUM HIGHLIGHTS</h2>
            </div>

            {curriculum.map(({ id, desc }) => {
              return (
                <div className="about-content">
                  <p>{desc}</p>
                  <div className="underline-box"></div>
                </div>
              );
            })}
          </div>

          <div className="lower2">
            <div className="content-heading">
              <h2>INFRASTRUCTURE AND FACILITIES</h2>
            </div>

            {infrastructure.map(({ id, desc }) => {
              return (
                <div className="about-content">
                  <p>
                    {desc}
                  </p>
                  <div className="underline-box"></div>
                </div>
              );
            })}

          </div>
        </div>
      </div>

      
      <Footer />

    </div>
  );
};

export default About;
