import React, {useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import DonationForm from "../../components/DonationForm/DonationForm";
import "./Donation.css";


const Donation = () => {
  return (
    <div>
      <section className="home">
        <Navbar />
        <div className="about-title">
          <h2>
            VB-ITR <span>/DONATION</span>
          </h2>
        </div>
      </section>

      
      <DonationForm />
      <Footer />
    </div>
  );
};

export default Donation;
