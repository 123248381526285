import React from "react";
import "./Footer.css";
import img1 from "../../assets/images/logo.png";
import { ImFacebook } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import img2 from "../../assets/images/dhe.png";
import img3 from "../../assets/images/tre-dul.png";
import img4 from "../../assets/images/vidyabharti.png";
import img5 from "../../assets/images/shiksha.png";
import img6 from "../../assets/images/vi.png";
import img9 from "../../assets/images/job360.png";
import img11 from "../../assets/images/sarvatra.png";
import img8 from "../../assets/images/tuduu.png";
import img7 from "../../assets/images/pooja.png";
import img10 from "../../assets/images/holistic.jpeg";
import img12 from "../../assets/images/sb.png"
const logos = [
  {
    id: 1,
    imgSrc: img1,
    links: "https://www.itrchandigarh.org/"
    
  },
  {
    id: 2,
    imgSrc: img2,
    links: "https://www.dhe.org.in/",
  },
  {
    id: 3,
    imgSrc: img3,
    links: "https://tredul.in/"
  },
  {
    id: 4,
    imgSrc: img4,
    links: "https://vidyabharti.net/"
  },
  {
    id: 5,
    imgSrc: img5,
    links: "https://www.rase.co.in/"
  },
  {
    id: 6,
    imgSrc: img6,
    links: "https://vi.rase.co.in/"
  },
  {
    id: 7,
    imgSrc: img7,
    links: "https://poojawala.in/"
  },
  {
    id: 8,
    imgSrc: img8,
    links: "https://tudu.co.in/"
  },
  {
    id: 9,
    imgSrc: img9,
    links: "https://jobs360degree.com/"
  },
  {
    id: 10,
    imgSrc: img10,
    links: "https://www.alltemples.org.in/"
  },
  {
    id: 11,
    imgSrc: img11,
    links: "https://sarvatr.co.in/"
  },
  {
    id: 12,
    imgSrc: img12,
    links: "https://swadeshibazaar.co.in/"
  },
];

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="location-logo">
          {/* <img src={img1} alt="" /> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.6604613704817!2d76.70609037551208!3d30.699827987222243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef39a32ed3c1%3A0x9ff15a51ad5117e9!2sDepartment%20of%20Holistic%20Education!5e0!3m2!1sen!2sin!4v1706939393027!5m2!1sen!2sin"
            width={350}
            height={250}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
         
        </div>

        <div className="contact">
          <form action="">
            <h4 className="heading">CONTACT US</h4>

            <div className="contact-container">
              <input type="text" className="name" placeholder="Name" />
              <input
                type="email"
                className="institute"
                placeholder="Enter Your Email"
              />
              <input
                type="tel"
                className="institute"
                placeholder="Mobile No."
              />
              <input type="text" className="query" placeholder="Query" />
              <button type="button" value={SubmitEvent} className="btn-1">
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="link">
          <h4 className="heading">USEFUL LINKS</h4>
          <div>
            <div className="link-parent">
          <div className="link-container">
            {logos.map(({ id, imgSrc, links }) => {
              return (
                <a
                  className="top-link"
                  href={links}
                  target="_blank"
                  id={id}
                >
                  <img src={imgSrc} alt="" />
                </a>
              );
            })}
          </div>
          </div>
          <div className="follow">
            <h4 className="heading">FOLLOW US</h4>

            <div className="follow-container">
              <a
                className="social-link"
                href="https://www.facebook.com/profile.php?id=61556039249506&mibextid=ZbWKwL"
                target="_blank"
              >
                {" "}
                <ImFacebook className="icon" />
              </a>
              <a
                className="social-link"
                href="https://twitter.com/vb_itrchd"
                target="_blank"
              >
                {" "}
                <FaXTwitter className="icon"/>{" "}
              </a>
              <a
                className="social-link"
                href="https://www.instagram.com/itrchandigarh?igsh=ZTdsazc1a3ZxYmNu"
                target="_blank"
              >
                {" "}
                <AiFillInstagram className="icon" />{" "}
              </a>
              <a
                className="social-link"
                href="https://www.youtube.com/channel/UCd-_B2IbovCDgVI1_I5ORGg"
                target="_blank"
              >
                <FaYoutube className="icon" />{" "}
              </a>
              <a
                className="social-link"
                href="https://www.linkedin.com/in/vb-institute-of-training-and-research-chandigarh-2121562b1/"
                target="_blank"
              >
                <FaLinkedin className="icon" />{" "}
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <p>
          &copy; {new Date().getFullYear()} Department of Holistic Education.
        </p>
      </div>
    </div>
  );
};

export default Footer;
