import React, { useState } from 'react';
import "./SignUp.css"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();
  const [formData , setFromData]= useState({
    email:"", 
    password:"",
   
  })
  const [message, setMessage] = useState("");

  const [ confirmPassword, setConfirmPassword] =useState("");
  
  const postSignupData = async (e) => {
    e.preventDefault()
    try {
      if(formData.password===confirmPassword){
      const url =  process.env.REACT_APP_BASEURL + 'signup';
      console.log(formData);
      const response= await axios.post(url , formData);
      console.log(response);
      navigate('/Login');
      }
      else{
        
      }
    } catch (error) {
      console.log(error);
    }
  }

  const emailValidation = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if(regEx.test(formData.email))
    {
      setMessage("email is valid")
    }
    else if((!formData.email) && formData.email !== " "){
      setMessage("email is not valid")
    }
    else{
      setMessage("")
    }
  };
  return (
    <div className="signup-container">
      <div className="form-container">
        <form className="form"  onSubmit={postSignupData}>
          <div className="input-container">
            <label htmlFor="email" className="input-label">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={(e) => setFromData({ ...formData, [e.target.name]: e.target.value })}
              autoComplete="email"
              required
              className="input-field"
              placeholder="Email address"
            />
          </div>
          <div className="input-container">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={(e) => setFromData({ ...formData, [e.target.name]: e.target.value })}

              autoComplete="current-password"
              required
              className="input-field"
              placeholder="Password"
            />
          </div>
          <div className="input-container">
            <label htmlFor="password" className="input-label">
            Confirm Password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="current-password"
              required
              className="input-field"
              placeholder="Confirm Password"
            />
          </div>
          <div className="button-container">
            <button  className="submit-button" onClick={emailValidation}>
              Sign Up
            </button>
            {message}
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
