import React from "react";
import "./Newsletter.css";

const Newsletter = () => {
  return (
    <div className="newsletter">
      <div className="newsletter-container">
        <div className="newsletter-content">
          <h2>Subscribe Our Newsletter&nbsp;&nbsp;</h2>
          <div className="email-container">
            <input type="email" placeholder="Enter your email" />
            <button type="button" value={SubmitEvent} className="btn-1">
                Subscribe
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
