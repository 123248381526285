import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import "./Gallery.css";
import img1 from "../../assets/images/gallery1.jpeg";
import img2 from "../../assets/images/gallery2.jpeg";
import img3 from "../../assets/images/gallery3.jpeg";
import img4 from "../../assets/images/gallery4.jpeg";
import img5 from "../../assets/images/gallery5.jpeg";
import img6 from "../../assets/images/gallery6.jpeg";
import img7 from "../../assets/images/gallery7.jpeg";
import img8 from "../../assets/images/gallery8.jpeg";

const imag = [
  { id: "1", imgSrc: img1 },
  { id: "2", imgSrc: img2 },
  { id: "3", imgSrc: img3 },
  { id: "4", imgSrc: img4 },
  { id: "5", imgSrc: img5 },
  { id: "6", imgSrc: img6 },
  { id: "7", imgSrc: img7 },
  { id: "8", imgSrc: img8 },
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleModal = (id) => {
    setSelectedImage(selectedImage === id ? null : id);
  };

  return (
    <>
      <div className="container">
        <h2 className="heading">GALLERY</h2>
        <div className="box">
          <Marquee>
            {imag.map(({ id, imgSrc }) => (
              <img
                src={imgSrc}
                alt=""
                onClick={() => toggleModal(id)}
                id={id}
                key={id}
                className={selectedImage === id ? "selected" : ""}
              />
            ))}
          </Marquee>
        </div>
      </div>{" "}

      {selectedImage && (
        <div className="modal">
          <div className="modal-contents">
            <img src={imag.find((img) => img.id === selectedImage).imgSrc} alt="" />
          </div>
          <div className="overlay" onClick={() => toggleModal(selectedImage)}></div>
        </div>
      )}
    </>
  );
};

export default Gallery;
