import React, { useState } from 'react';
import axios from 'axios';
import "./Login.css"
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        const url = process.env.REACT_APP_BASEURL + 'login';
        try {
            await axios.post(url, {
                email,password
            })
            .then(res=>{
               
                console.log(res);
                if(res.status===200)
                {
                    navigate('/admin/home');
                }
                else
                {
                    alert("user have not sign up")
                }
            })
            .catch(e=>{
                alert("wrong details");
                console.log(e);
            })
            
        } catch (error) {
            console.log(error);
        }
    }
  return (
    <>
      <div className="login-container">
        

        <div className="loginform-container">
          <form className="loginform" onSubmit={submit}>
            <div className="input-group">
              <label className="label" htmlFor="email">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
                className="input"
              />
            </div>

            <div className="input-group">
              <div className="password-label-group">
                <label className="label" htmlFor="password">
                  Password
                </label>
                
                <a href="#" className="forgot-password">
                  Forgot password?
                </a>
              </div>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="input"
              />
            </div>

            <div>
              <button
                type="submit"
                className="submit-button"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="signup-link">
            Not a member?{' '}
            <a href="#" className="signup-link-text">
              Start a 14 day free trial
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Login;
