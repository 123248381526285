import React, { useEffect, useState } from "react";
import "./Announcement.css";
import axios from "axios";

import { MdOutlineAccessTimeFilled } from "react-icons/md";

const Announcement = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  const getEvents = async () => {
    try {
      const url = process.env.REACT_APP_BASEURL + "events";
      // console.log(url);
      const res = await axios.get(url);
      setEvents(res.data);
      setLoading(true);
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getEvents();
  }, []);

  const getNews = async () => {
    try {
      const url = process.env.REACT_APP_BASEURL + "news";
      const res = await axios.get(url);
      setNews(res.data);
      console.log(res);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const getAnnouncements = async () => {
    try {
      const url = process.env.REACT_APP_BASEURL + "announcements";
      const res = await axios.get(url);
      setAnnouncements(res.data);
      console.log(res);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <div className="announcement">
      <div className="right-container">
        <div className="news space">
          <h2 className="content-heading">NEWS</h2>
          {!loading && <div>loading.....</div>}
          {loading && (
            <div className="news-container">
              {news.map(({ id, image, imgTitle, headline, desc, newsLink }) => {
                return (
                  <div className="news-content">
                    <div className="news-img">
                      <img
                        src={`${process.env.REACT_APP_BASEURL}${image.path}`}
                        alt={imgTitle}
                      />
                    </div>

                    <div className="news-text">
                      <h5 className="news-headline">{headline}</h5>
                      <a href={newsLink} target="_blank">
                        <p className="news-para">{desc}</p>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="left-container">
        <div className="announcements space">
          <h2 className="content-heading">ANNOUNCEMENT</h2>
          {!loading && <div>loading.....</div>}
          {loading && (
            <div className="announcement-container">
              {announcements.map(({ id, desc }) => {
                return (
                  <div className="announcements-content">
                    <p>{desc}</p>
                    <div className="underline-box"></div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="event">
          <h2 className="content-heading">EVENT CALENDAR</h2>
          {!loading && <div>loading.....</div>}
          {loading && (
            <div className="event-scroll">
              {events.map(({ id, title, date }) => (
                <div key={id} className="event-container">
                  <div className="event-container">
                    <div className="event-content">
                      <div className="event-headline">
                        <h6>{title}</h6>
                      </div>
                      <div className="event-text">
                        <MdOutlineAccessTimeFilled className="png" />
                        <p>{date}</p>
                      </div>
                      <div className="underline-box"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Announcement;
