import React from 'react';
import "./Mission.css"

const Mission = () => {
  return (
    <>
      <div className='mission'>
        <h2 className='heading'>MISSION</h2>
        <p className='para'><span>Our mission is to cultivate a culture of lifelong learning and scholarly inquiry. We aim to empower individuals with the skills, knowledge, and critical thinking abilities needed to excel in their professional endeavors, all while contributing to the broader body of knowledge through impactful research initiatives.</span></p>
      </div>
      <div className='mission'>
        <h2 className='heading'>VISION</h2>
        <p>At the Institute of Training and Research, our vision is to be a catalyst for transformative learning and pioneering research. We strive to create an environment where knowledge is not only disseminated but actively generated, pushing the boundaries of what is possible in training and research.</p>
      </div>
    </>
  );
}

export default Mission;
